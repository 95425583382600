<script lang="ts">
    import { floors } from "./floors.json";
    import Floor from "./Floor.svelte";

    import activeFloor from "../floors.js";
    
    var floor_keys = Object.keys(floors);
</script>



{#each floor_keys as floor}
    {#if $activeFloor == floor}
        <Floor floorname={floor}/>
    {/if}
{/each}