<script lang="ts">
    import { floors } from "./floors.json";

    export let floorname;

    const image_path = floors[floorname]["path"];

    const id = "backimg-"+floorname;
</script>

<style>
    img {
        margin: 0;
        padding: 0;
        display: inline-block;
        top: 0;
        left: 0;
        transition-property: opacity;
        transition-duration: 1s;
        z-index: -1;
    }
</style>

<!-- svelte-ignore a11y-missing-attribute -->
<img src={image_path} id={id}/>